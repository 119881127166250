
import { Component, Prop, Vue } from 'vue-property-decorator';
import SmallDashboardCard from '../SmallDashboardCard.vue';

@Component({
  components: {
    SmallDashboardCard,
  },
})
export default class LinkDashboardCard extends Vue {
  @Prop({ required: false })
  content?: any;
  @Prop({ required: false })
  element?: any;
}
